import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import Img from "gatsby-image"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Header from "../components/header"
import ImageAndCopySection from "../components/image-and-copy-section"
import VideoSection from "../components/video"
// import TestimonialSection from "../components/testimonial-section"
import Gallery from "../components/gallery-section"
import Quote from "../components/quote"
import ContactSection from "../components/contact-section"
import RealWeddingSection from "../components/real-wedding-section"
import FloorPlan from "../components/floor-plan"

import { MenuSubheading, Copy, Subheading } from "../components/typography"

const PreferredVendorGrid = styled.div`
  width: calc(100% - 60px);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  padding: 0 30px;

  align-items: center;
  justify-items: center;
  gap: 60px;
  margin-bottom: 60px;

  & > * {
    min-width: 44px;
    min-height: 44px;
    width: stretch;
    height: stretch;
    max-width: 176px;
    max-height: 176px;
  }

  @media screen and (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (min-width: 1200px) {
    margin: 120px auto;
    width: 1110px;
    & > * {
      margin: 0;
    }
  }
`

const Page = ({
  data: {
    contentfulPage: { seoTitle, seoDescription, seoKeywords, content },
  },
}) => (
  <Layout>
    <SEO
      title={seoTitle}
      description={seoDescription}
      meta={[{ name: "keywords", content: seoKeywords.join(", ") }]}
    />
    {content.map((contentItem, index) =>
      contentItem.__typename === "ContentfulHeaderFullImageSection" ? (
        <Header
          key={index}
          contentOnRight={index % 2 === 0 ? true : false}
          image={contentItem.image}
          title={contentItem.title}
          text={contentItem.copy ? contentItem.copy.copy : null}
          link={contentItem.linkAddress}
          linkText={contentItem.linkText}
        />
      ) : contentItem.__typename === "ContentfulPromotion" ? (
        <ImageAndCopySection
          key={index}
          contentOnRight={index % 2 === 0 ? true : false}
          image={contentItem.image}
          title={contentItem.title}
          text={
            contentItem.description ? contentItem.description.description : null
          }
          price={contentItem.price}
          link={`/promotions/${contentItem.slug}/`}
          linkText={"Learn More"}
        />
      ) : contentItem.__typename === "ContentfulImageAndCopySection" ? (
        <ImageAndCopySection
          key={index}
          contentOnRight={index % 2 === 0 ? true : false}
          image={contentItem.image}
          aboveTitleText={contentItem.aboveTitleText}
          title={contentItem.title}
          text={contentItem.copy ? contentItem.copy.copy : null}
          internalPageLinks={contentItem.internalPageLinks}
        />
      ) : contentItem.__typename === "ContentfulVideoSection" ? (
        <VideoSection
          key={index}
          video={contentItem.video}
          captions={contentItem.captions}
          poster={contentItem.poster}
          description={
            contentItem.description ? contentItem.description.description : null
          }
        />
      ) : // ) : contentItem.__typename === "ContentfulTestimonial" ? (
      //   <TestimonialSection
      //     key={index}
      //     index={index + 1}
      //     contentOnRight={index % 2 === 0 ? true : false}
      //     image={contentItem.image}
      //     title={contentItem.title}
      //     text={contentItem.copy ? contentItem.copy.copy : null}
      //     quote={contentItem.quote ? contentItem.quote.quote : null}
      //   />
      contentItem.__typename === "ContentfulMenuHeading" ? (
        <div key={index} style={{ margin: "0 25px 60px" }}>
          {contentItem.menuSectionHeading ? (
            <MenuSubheading>{contentItem.menuSectionHeading}</MenuSubheading>
          ) : null}
          {contentItem.menuSubsectionHeading ? (
            <Subheading>{contentItem.menuSubsectionHeading}</Subheading>
          ) : null}
          {contentItem.menuDescription
            ? contentItem.menuDescription.menuDescription
                .split("\n")
                .map((paragraph, paragraphIndex) => (
                  <Copy key={paragraphIndex}>{paragraph}</Copy>
                ))
            : null}
        </div>
      ) : contentItem.__typename === "ContentfulVenue" ? (
        <ImageAndCopySection
          key={index}
          contentOnRight={index % 2 === 0 ? true : false}
          image={contentItem.image}
          title={contentItem.venueName}
          venueGem={contentItem.venueGem}
          capacity={contentItem.capacity}
          squareFootage={contentItem.squareFootage}
          dimensions={contentItem.dimensions}
          extras={contentItem.extras}
          link={`/venues/${contentItem.slug}/`}
          linkText={"Learn More"}
        />
      ) : contentItem.__typename === "ContentfulPreferredVendorSection" ? (
        <PreferredVendorGrid key={index}>
          {contentItem.preferredVendors.map((preferredVendor, index) => (
            <a
              href={preferredVendor.link}
              name={`Link to the ${preferredVendor.name} website.`}
              key={index}
            >
              {preferredVendor.image.fluid ? (
                <Img
                  fluid={preferredVendor.image.fluid}
                  alt={preferredVendor.image.description || "Background Image"}
                />
              ) : preferredVendor.image.file.url ? (
                <img
                  src={preferredVendor.image.file.url}
                  alt={preferredVendor.image.description || "Background Image"}
                />
              ) : null}
            </a>
          ))}
        </PreferredVendorGrid>
      ) : contentItem.__typename === "ContentfulGallery" ? (
        <Gallery
          key={index}
          contentOnRight={index % 2 === 0 ? true : false}
          title={contentItem.title}
          copy={contentItem.copy ? contentItem.copy.copy : null}
          gallery={contentItem.gallery}
        />
      ) : contentItem.__typename === "ContentfulQuote" ? (
        <Quote
          key={index}
          quote={contentItem.quote ? contentItem.quote.quote : ""}
        />
      ) : contentItem.__typename === "ContentfulMap" ? (
        <ContactSection
          key={index}
          coordinates={contentItem.mapCenterPoint}
          icon={contentItem.mapCenterPointIcon}
          link={contentItem.mapLink}
          title={contentItem.title}
          contactMethods={contentItem.contactMethods}
          additionalContent={contentItem.additionalContent}
        />
      ) : contentItem.__typename === "ContentfulRealWeddingsPost" ? (
        <React.Fragment key={index}>
          {index === 1 ? (
            <ImageAndCopySection
              contentOnRight={index % 2 === 0 ? true : false}
              image={contentItem.image}
              subtitle={contentItem.subtitle}
              title={contentItem.title}
              text={contentItem.summary ? contentItem.summary.summary : null}
              link={`/real-weddings/${contentItem.slug}/`}
              linkText={"Read More"}
              realWedding={true}
            />
          ) : (
            <RealWeddingSection
              image={contentItem.image}
              subtitle={contentItem.subtitle}
              title={contentItem.title}
              summary={contentItem.summary ? contentItem.summary.summary : null}
              slug={contentItem.slug}
            />
          )}
        </React.Fragment>
      ) : contentItem.__typename === "ContentfulFloorPlan" ? (
        <FloorPlan key={index} floorPlanContent={contentItem} />
      ) : null
    )}
  </Layout>
)

export default Page

Page.propTypes = {
  data: PropTypes.shape({
    contentfulPage: PropTypes.shape({
      seoTitle: PropTypes.string,
      seoDescription: PropTypes.string,
      seoKeywords: PropTypes.array,
      content: PropTypes.arrayOf(
        PropTypes.oneOfType([
          // ContentfulHeaderFullImageSection
          PropTypes.shape({
            __typename: PropTypes.string,
            image: PropTypes.shape({
              description: PropTypes.string,
              file: PropTypes.shape({
                url: PropTypes.string,
                details: PropTypes.shape({
                  image: PropTypes.shape({
                    height: PropTypes.number,
                    width: PropTypes.number,
                  }),
                }),
              }),
              fluid: PropTypes.object,
            }),
            title: PropTypes.string,
            copy: PropTypes.shape({
              copy: PropTypes.string,
            }),
            linkAddress: PropTypes.string,
            linkText: PropTypes.string,
          }),
          // ContentfulPromotion
          PropTypes.shape({
            __typename: PropTypes.string,
            image: PropTypes.shape({
              description: PropTypes.string,
              file: PropTypes.shape({
                url: PropTypes.string,
                details: PropTypes.shape({
                  image: PropTypes.shape({
                    height: PropTypes.number,
                    width: PropTypes.number,
                  }),
                }),
              }),
              fluid: PropTypes.object,
            }),
            title: PropTypes.string,
            description: PropTypes.shape({
              description: PropTypes.string,
            }),
            price: PropTypes.string,
            slug: PropTypes.string,
          }),
          // ContentfulImageAndCopySection
          PropTypes.shape({
            __typename: PropTypes.string,
            image: PropTypes.shape({
              description: PropTypes.string,
              file: PropTypes.shape({
                url: PropTypes.string,
                details: PropTypes.shape({
                  image: PropTypes.shape({
                    height: PropTypes.number,
                    width: PropTypes.number,
                  }),
                }),
              }),
              fluid: PropTypes.object,
            }),
            aboveTitleText: PropTypes.string,
            title: PropTypes.string,
            copy: PropTypes.shape({
              copy: PropTypes.string,
            }),
            linkAddress: PropTypes.string,
            linkText: PropTypes.string,
            internalPageLinks: PropTypes.arrayOf(
              PropTypes.shape({
                slug: PropTypes.string,
                menuName: PropTypes.string,
              }),
              PropTypes.shape({
                slug: PropTypes.string,
                title: PropTypes.string,
              }),
              PropTypes.shape({
                menuName: PropTypes.string,
                pdf: PropTypes.shape({
                  file: PropTypes.shape({
                    url: PropTypes.string,
                  }),
                }),
              })
            ),
          }),
        ])
      ),
    }),
  }),
}

export const query = graphql`
  query PageQuery($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      seoTitle
      seoDescription
      seoKeywords
      content {
        ... on ContentfulHeaderFullImageSection {
          image {
            description
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
            fluid(maxWidth: 2400) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          title
          copy {
            copy
          }
          linkAddress
          linkText
        }
        ... on ContentfulPromotion {
          image {
            description
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
            fluid(maxWidth: 1500) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          title
          description {
            description
          }
          price
          slug
        }
        ... on ContentfulImageAndCopySection {
          image {
            description
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
            fluid(maxWidth: 1500) {
              ...GatsbyContentfulFluid_withWebp
            }
            title
          }
          aboveTitleText
          title
          copy {
            copy
          }
          linkAddress
          linkText
          internalPageLinks {
            ... on ContentfulMenu {
              slug
              menuName
            }
            ... on ContentfulPdfMenu {
              menuName
              pdf {
                file {
                  url
                }
              }
            }
            ... on ContentfulPromotion {
              slug
              title
            }
          }
        }
        # ... on ContentfulTestimonial {
        #   image {
        #     description
        #     file {
        #       url
        #       details {
        #         image {
        #           height
        #           width
        #         }
        #       }
        #     }
        #     fluid {
        #       ...GatsbyContentfulFluid_withWebp
        #     }
        #   }
        #   title
        #   copy {
        #     copy
        #   }
        #   quote {
        #     quote
        #   }
        # }
        ... on ContentfulVideoSection {
          video {
            description
            file {
              contentType
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
          captions {
            file {
              contentType
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
          poster {
            description
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
          description {
            description
          }
        }
        ... on ContentfulMenuHeading {
          menuSectionHeading
          menuSubsectionHeading
          menuDescription {
            menuDescription
          }
        }
        ... on ContentfulVenue {
          slug
          image {
            description
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
            fluid(maxWidth: 1500) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          venueName
          venueGem {
            description
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          capacity
          squareFootage
          dimensions
          extras
          content {
            image {
              description
              file {
                url
                details {
                  image {
                    height
                    width
                  }
                }
              }
              fluid(maxWidth: 1500) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            title
            copy {
              copy
            }
          }
        }
        ... on ContentfulPreferredVendorSection {
          preferredVendors {
            image {
              description
              file {
                url
                details {
                  image {
                    height
                    width
                  }
                }
              }
              fluid {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            name
            link
          }
        }
        ... on ContentfulQuote {
          quote {
            quote
          }
        }
        ... on ContentfulGallery {
          title
          copy {
            copy
          }
          gallery {
            description
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
            fluid(maxWidth: 1500) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
        ... on ContentfulMap {
          mapCenterPoint {
            lat
            lon
          }
          mapCenterPointIcon {
            description
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          mapLink
          title
          contactMethods {
            label
            text
            linkAddress
            icon {
              description
              file {
                url
                details {
                  image {
                    height
                    width
                  }
                }
              }
              fluid {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
          additionalContent {
            menuSectionHeading
            menuDescription {
              menuDescription
            }
          }
        }
        ... on ContentfulRealWeddingsPost {
          image {
            description
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
            fluid(maxWidth: 1500) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          subtitle
          title
          summary {
            summary
          }
          slug
        }
        ... on ContentfulFloorPlan {
          floorPlan {
            description
            file {
              url
            }
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          listOfRooms {
            slug
            venueName
            venueGem {
              description
              file {
                url
              }
              fluid {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            capacity
            squareFootage
          }
        }
      }
    }
  }
`
