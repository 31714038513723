import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import useWindowSize from "./use-window-size"

import { MenuThirdHeading } from "./typography"

const VideoSection = styled.section`
  margin: 0 25px 60px;

  opacity: ${(props) => (props.hideOnScroll ? "0" : "1")};
  transform: ${(props) =>
    props.hideOnScroll ? "translateY(50px)" : "translateY(0px)"};

  transition-property: opacity, transform;
  transition-duration: 500ms;
  transition-timing-function: ease-in-out;
  transition-delay: 200ms;

  & > video {
    width: 100%;
  }

  @media screen and (min-width: 1200px) {
    margin: 120px auto;
    & > video {
      width: 1080px;
      margin: 0 auto;
    }
  }
`

const ImageAndCopySectionComponent = ({
  video,
  captions,
  poster,
  description,
}) => {
  const windowSize = useWindowSize()
  const elementRef = useRef(0)
  const [hideOnScroll, setHideOnScroll] = useState(true)
  useScrollPosition(
    () =>
      setHideOnScroll(
        elementRef.current.getBoundingClientRect().top > windowSize.height
      ),
    [hideOnScroll]
  )

  return (
    <VideoSection ref={elementRef} hideOnScroll={hideOnScroll}>
      <video controls poster={poster.file.url ? poster.file.url : null}>
        <source src={video.file.url} type={video.file.contentType} />
        <track
          //   default
          kind="captions"
          srcLang="en"
          src={
            captions.file.contentType === "text/vtt" ? captions.file.url : null
          }
        />
        <p>
          Your browser doesn’t support HTML5 video. Here is a
          <a href={video.file.url} target="_blank" rel="noopener noreferrer">
            link to the video
          </a>{" "}
          instead.
        </p>
      </video>
      <MenuThirdHeading>{description}</MenuThirdHeading>
    </VideoSection>
  )
}

export default ImageAndCopySectionComponent

ImageAndCopySectionComponent.propTypes = {
  video: PropTypes.shape({
    description: PropTypes.string.isRequired,
    file: PropTypes.shape({
      contentType: PropTypes.string,
      url: PropTypes.string,
    }),
  }),
  captions: PropTypes.shape({
    file: PropTypes.shape({
      contentType: PropTypes.string,
      url: PropTypes.string,
    }),
  }),
  poster: PropTypes.shape({
    description: PropTypes.string.isRequired,
    file: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  //   contentOnRight: PropTypes.bool,
  description: PropTypes.string,
}
