import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import useWindowSize from "./use-window-size"

import { MenuSubheading, Copy } from "./typography"

const FloorPlanStyle = styled.div`
  display: grid;
  row-gap: 15px;
  margin-top: 30px;
  margin-bottom: 30px;

  opacity: ${(props) => (props.hideOnScroll ? "0" : "1")};
  transform: ${(props) =>
    props.hideOnScroll ? "translateY(50px)" : "translateY(0px)"};

  transition-property: opacity, transform;
  transition-duration: 500ms;
  transition-timing-function: ease-in-out;
  transition-delay: 200ms;

  & > :nth-child(2) > * {
    margin-left: 15px;
    margin-right: 15px;

    display: grid;
    grid-template-columns: 44px 1fr 32px;
    column-gap: 15px;
    align-items: center;

    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #3c3c3c;

    & > * {
      grid-row: 1 / span 2;
    }
    & > :first-child {
      grid-column: 1;
    }

    /* h2 - Venue Title*/
    & > :nth-child(2) {
      grid-column: 2;
      grid-row: 1;
      margin-bottom: 0;
      transform: translateY(3px);
    }
    & > :nth-child(3) {
      grid-column: 2;
      grid-row: 2;
    }
    & > :last-child {
      grid-column: 3;
      & > * {
        fill: none;
        stroke: #3c3c3c;
        stroke-miterlimit: 10;
      }
    }

    &:last-of-type {
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  @media screen and (min-width: 768px) {
    margin-top: 60px;
    margin-bottom: 60px;
    & > :nth-child(2) > * {
      margin-left: 30px;
      margin-right: 30px;
    }
  }

  @media screen and (min-width: 992px) {
    grid-template-columns: 1fr auto;

    & > :first-child {
      grid-column: 1;
      width: 100%;
    }

    & > :nth-child(2) {
      grid-column: 2;
    }
  }

  @media screen and (min-width: 1200px) {
    margin: 120px auto;
    width: 1110px;
  }
`

const FloorPlan = ({ floorPlanContent }) => {
  const windowSize = useWindowSize()

  const elementRef = useRef(0)
  const [hideOnScroll, setHideOnScroll] = useState(true)
  useScrollPosition(
    () =>
      setHideOnScroll(
        elementRef.current.getBoundingClientRect().top > windowSize.height
      ),
    [hideOnScroll]
  )
  return (
    <FloorPlanStyle ref={elementRef} hideOnScroll={hideOnScroll}>
      {floorPlanContent.floorPlan.fluid ? (
        <Img
          src={floorPlanContent.floorPlan.fluid}
          alt={floorPlanContent.floorPlan.description}
        />
      ) : floorPlanContent.floorPlan.file.url ? (
        <img
          src={floorPlanContent.floorPlan.file.url}
          alt={floorPlanContent.floorPlan.description}
        />
      ) : null}
      <div>
        {floorPlanContent.listOfRooms
          ? floorPlanContent.listOfRooms.map((room, index) => (
              <Link key={index} to={`/venues/${room.slug}/`}>
                {room.venueGem.fluid ? (
                  <Img
                    src={room.venueGem.fluid}
                    alt={room.venueGem.description}
                    style={{ height: "44px" }}
                  />
                ) : room.venueGem.file.url ? (
                  <img
                    src={room.venueGem.file.url}
                    alt={room.venueGem.description}
                    style={{ height: "44px" }}
                  />
                ) : null}
                {room.capacity || room.squareFootage ? (
                  <MenuSubheading style={{ marginTop: "0" }}>
                    {room.venueName}
                  </MenuSubheading>
                ) : (
                  <div
                    style={{
                      gridRow: "1 / span 2",
                      transform: "translateY(7px)",
                    }}
                  >
                    <MenuSubheading style={{ marginTop: "0" }}>
                      {room.venueName}
                    </MenuSubheading>
                  </div>
                )}
                {room.capacity || room.squareFootage ? (
                  <Copy>
                    {`${room.capacity ? room.capacity + " guests" : ""}${
                      room.capacity && room.squareFootage ? " / " : ""
                    }${
                      room.squareFootage
                        ? room.squareFootage + " area sq. ft"
                        : ""
                    }`}
                  </Copy>
                ) : null}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 32 32"
                  width="32"
                  height="32"
                  style={
                    room.capacity || room.squareFootage
                      ? null
                      : { transform: "translateY(-10px)" }
                  }
                >
                  <path d="M17,6l10,10L17,26" />
                  <line x1="26.5" y1="16" x2="5.5" y2="16" />
                </svg>
              </Link>
            ))
          : null}
      </div>
    </FloorPlanStyle>
  )
}

export default FloorPlan

FloorPlan.propTypes = {
  floorPlanContent: PropTypes.shape({
    floorPlan: PropTypes.shape({
      fluid: PropTypes.object,
      description: PropTypes.string.isRequired,
      file: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
    listOfRooms: PropTypes.arrayOf(
      PropTypes.shape({
        venueGem: PropTypes.shape({
          fluid: PropTypes.object,
          description: PropTypes.string.isRequired,
          file: PropTypes.shape({
            url: PropTypes.string,
            details: PropTypes.shape({
              image: PropTypes.shape({
                height: PropTypes.number,
                width: PropTypes.number,
              }),
            }),
          }),
        }),
        venueName: PropTypes.string,
        capacity: PropTypes.string,
        squareFootage: PropTypes.string,
      })
    ),
  }),
}
