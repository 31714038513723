import React, { useState } from "react"
import { navigate } from "gatsby-link"
import styled from "styled-components"
// import { v4 as uuidv4 } from "uuid"

import { MenuSubheading, Copy } from "./typography"

const ContactUsForm = styled.form`
  display: grid;
  width: fill-available;
  box-sizing: border-box;
  row-gap: 45px;
  padding-left: 30px;
  margin-top: 60px;
  margin-bottom: 60px;
  padding-right: 30px;

  * {
    outline: none;
  }

  & > * {
    grid-column: 1;
  }

  & > :nth-child(2) {
    margin-bottom: -15px;
  }

  & > label {
    color: hsl(0, 0%, 20%);
    & > :first-child {
      margin-bottom: 5px;
    }

    & > input,
    & > textarea {
      width: 100%;
      border: none;
      border-bottom: 1px solid hsl(0, 0%, 70%);
      transition: border 300ms ease-in-out;
      outline: none;

      font-style: normal;
      font-variant: normal;
      font-weight: 300;
      font-size: 15.5px;
      line-height: 1.625;
      font-family: "Sofia Pro", "Gill Sans", "Gill Sans MT", Calibri,
        "Trebuchet MS", sans-serif;
      letter-spacing: 0.04em;

      &:active,
      &:focus,
      &:hover {
        border-bottom: 1px solid hsl(0, 0%, 20%);
      }
    }

    & > textarea {
      min-height: 120px;
      resize: none;
    }
  }

  & > button {
    font-style: normal;
    font-variant: normal;
    font-weight: 300;
    font-size: 15.5px;
    line-height: 1.625;
    font-family: "Sofia Pro", "Gill Sans", "Gill Sans MT", Calibri,
      "Trebuchet MS", sans-serif;
    letter-spacing: 0.04em;

    width: fit-content;
    padding: 5px 20px 7px;
    border: 1px solid hsl(0, 0%, 70%);
    border-radius: 4px;
    /* cursor: pointer; */
    outline: none;
    transform: scale(100%);

    transition-property: border, transform;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;

    /* &,
    & * {
      outline: none;
      &:hover {
        outline: none;
      }
    } */

    & > * {
      margin: 0;
      outline: none;
    }

    &:focus,
    &:hover {
      border: 1px solid hsl(0, 0%, 20%);
      outline: none;
    }

    &:active {
      border: 1px solid hsl(0, 0%, 20%);
      background: hsl(0, 0%, 90%);
      outline: none;
      transform: scale(95%);
    }
  }

  @media screen and (min-width: 768px) {
    padding-left: 32px;
    padding-right: 30px;
    grid-column: 1 / -1;
    grid-template-columns: repeat(2, 1fr);
    /* grid-template-areas:
      "h2       h2"
      "name     ."
      "email    dates"
      "phone    dates"
      "method   questions"
      "size     questions"
      "button   ."; */
    grid-template-areas:
      "h2       h2"
      "name     email"
      "phone    size"
      "method     ."
      "dates    questions"
      "dates    questions"
      "button   .";
    column-gap: 30px;
    row-gap: 45px;

    & > :nth-child(2) {
      grid-area: h2;
    }

    & > :nth-child(3) {
      grid-area: name;
    }
    & > :nth-child(4) {
      grid-area: email;
    }
    & > :nth-child(5) {
      grid-area: phone;
    }
    & > :nth-child(6) {
      grid-area: method;
    }
    & > :nth-child(7) {
      grid-area: size;
    }
    & > :nth-child(8) {
      grid-area: dates;
      height: 100%;

      & > :first-child {
        height: 30px;
      }
      & > :nth-child(2) {
        height: calc(100% - 36px);
      }
    }

    & > :nth-child(9) {
      grid-area: questions;
      height: 100%;

      & > :first-child {
        height: 30px;
      }
      & > :nth-child(2) {
        height: calc(100% - 36px);
      }
    }

    & > button {
      grid-area: button;
      padding: 8px 30px 10px;
    }
  }

  @media screen and (min-width: 992px) {
    gap: 60px;
    margin: 60px auto;

    & label > :first-child {
      margin-bottom: 10px;
    }

    /* & > :nth-child(6) > :nth-child(2) {
      height: calc(100% - 41px);
    } */
  }
  @media screen and (min-width: 1200px) {
    column-gap: 90px;
    width: 1140px;

    & label > :first-child {
      margin-bottom: 30px;
    }

    /* & > :nth-child(6) > :nth-child(2) {
      height: calc(100% - 61px);
    } */
  }
`

const PreferredMethodOfContact = styled.div`
  display: grid;
  grid-template-columns: 44px 1fr 44px 1fr;
  align-items: center;
  row-gap: 10px;

  & > :first-child {
    grid-row: 1;
    grid-column: 1 / -1;
  }

  & > :nth-child(n + 2) {
    grid-row: 2;
    grid-column: auto;
    min-height: 16px;

    & > p {
      margin: 0;
      padding: 0;
    }
  }
`

const InterestedDates = styled.label``

// const InterestedDates = styled.div`
//   display: grid;
//   grid-template-columns: 1fr;
//   row-gap: 10px;

//   & > div {
//     display: grid;
//     grid-template-columns: 1fr 44px;
//     column-gap: 30px;

//     & > ::nth-child(2) {
//       justify-self: center;
//     }
//   }

//   & input {
//     border: none;
//     border-bottom: 1px solid hsl(0, 0%, 70%);
//     transition: border 300ms ease-in-out;
//     outline: none;

//     font-style: normal;
//     font-variant: normal;
//     font-weight: 300;
//     font-size: 15.5px;
//     line-height: 1.625;
//     font-family: "Sofia Pro", "Gill Sans", "Gill Sans MT", Calibri,
//       "Trebuchet MS", sans-serif;
//     letter-spacing: 0.04em;

//     &:active,
//     &:focus,
//     &:hover {
//       border-bottom: 1px solid hsl(0, 0%, 20%);
//     }
//   }
// `

const GroupSize = styled.div`
  display: flex;
  flex-direction: column;

  & > input {
    /* margin-top: 4.5px; */
    border: none;
    border-bottom: 1px solid hsl(0, 0%, 70%);
    transition: border 300ms ease-in-out;
    outline: none;

    font-style: normal;
    font-variant: normal;
    font-weight: 300;
    font-size: 15.5px;
    line-height: 1.625;
    font-family: "Sofia Pro", "Gill Sans", "Gill Sans MT", Calibri,
      "Trebuchet MS", sans-serif;
    letter-spacing: 0.04em;

    &:active,
    &:focus,
    &:hover {
      border-bottom: 1px solid hsl(0, 0%, 20%);
    }
  }
`

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const Form = () => {
  const [state, setState] = useState({})

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error))
  }

  // const [list, setList] = useState([
  //   { value: new Date().toISOString().split("T")[0], id: uuidv4() },
  // ])

  // function handleDateChange(event) {
  //   const updatedList = list.map((listItem) =>
  //     listItem.id === event.target.id
  //       ? { value: event.target.value, id: listItem.id }
  //       : listItem
  //   )
  //   setList(updatedList)
  // }

  // function handleAddDate() {
  //   const newList = list.concat({
  //     value: new Date().toISOString().split("T")[0],
  //     id: uuidv4(),
  //   })
  //   setList(newList)
  // }

  // function handleSubtractDate(e) {
  //   const newList = list.filter((listItem) => listItem.id !== e.target.name)
  //   setList(newList)
  //   // const updatedState = state.filter(stateItem => stateItem !== e.target.value)
  //   // const updatedState = Object.keys(state)
  //   //   .filter((key) => key !== e.target.id)
  //   //   .reduce((res, key) => ((res[key] = state[key]), res), {})
  //   const updatedState = delete state[e.target.id]
  //   console.log(updatedState)
  //   setState(updatedState)
  // }

  return (
    <ContactUsForm
      name="Contact"
      method="POST"
      action="/thanks/"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      <p hidden>
        <label>
          Don’t fill this out:{" "}
          <input name="bot-field" onChange={handleChange} />
        </label>
      </p>
      <MenuSubheading>Contact Us</MenuSubheading>
      <label>
        <Copy>Name</Copy>
        <input type="text" name="name" required onChange={handleChange} />
      </label>
      <label>
        <Copy>Email</Copy>
        <input type="email" name="email" required onChange={handleChange} />
      </label>
      <label>
        <Copy>Phone</Copy>
        <input type="tel" name="phone" required onChange={handleChange} />
      </label>
      <PreferredMethodOfContact>
        <label htmlFor="preferred-contact-method">
          <Copy>Preferred Method of Contact</Copy>
        </label>
        <input
          type="radio"
          id="email-preferred"
          name="preferred-contact-method"
          value="email-preferred"
          required
          onChange={handleChange}
        />
        <label htmlFor="email-preferred">
          <Copy>Email Preferred</Copy>
        </label>
        <input
          type="radio"
          id="phone-preferred"
          name="preferred-contact-method"
          value="phone-preferred"
          required
          onChange={handleChange}
        />
        <label htmlFor="phone-preferred">
          <Copy>Phone Preferred</Copy>
        </label>
      </PreferredMethodOfContact>
      <GroupSize>
        <label htmlFor="group-size">
          <Copy>Group Size</Copy>
        </label>
        <input
          type="number"
          id="group-size"
          name="group-size"
          min="2"
          max="2000"
          step="1"
          required
          onChange={handleChange}
        />
      </GroupSize>
      <InterestedDates>
        <Copy>Interested Dates</Copy>
        <textarea name="interested-dates" required onChange={handleChange} />
      </InterestedDates>

      <label>
        <Copy>Comments or Questions</Copy>
        <textarea name="message" required onChange={handleChange}></textarea>
      </label>
      <button type="submit">
        <Copy>Send</Copy>
      </button>
    </ContactUsForm>
  )
}

export default Form

/* <InterestedDates>
        <label htmlFor="interested-dates">Interested Dates</label>
        {list.map((listItem, index) => (
          <div key={index}>
            <input
              type="date"
              id={listItem.id}
              name={`interested-dates-${index + 1}`}
              min={new Date().toISOString().split("T")[0]}
              max="2100-01-01"
              // value={listItem.value}
              // onChange={() => {
              //   handleDateChange
              //   handleChange
              // }}
              onChange={handleChange}
              required
            />
            {index ? (
              <button
                id={`interested-dates-${index + 1}`}
                name={listItem.id}
                onClick={handleSubtractDate}
              >
                ✕
              </button>
            ) : null}
          </div>
        ))}
        <button onClick={handleAddDate}>+</button>
      </InterestedDates> */
