import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Img from "gatsby-image"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import useWindowSize from "./use-window-size"

import {
  RealWeddingsThirdHeading,
  MenuSubheading,
  Copy,
  StyledLink,
} from "./typography"

const RealWeddingSection = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;

  opacity: ${(props) => (props.hideOnScroll ? "0" : "1")};
  transform: ${(props) =>
    props.hideOnScroll ? "translateY(50px)" : "translateY(0px)"};

  transition-property: opacity, transform;
  transition-duration: 500ms;
  transition-timing-function: ease-in-out;
  transition-delay: 200ms;

  & > * {
    grid-column: 1;
  }

  & > :first-child {
    grid-row: 1;
    min-height: calc(100vw * 3 / 4);
    max-height: calc(100vw * 4 / 3);
    width: 100%;
    object-fit: cover;
  }

  & > :nth-child(2) {
    grid-row: 1;
    z-index: 1;
    margin: 20px 15px;
    width: calc(100% - 32px);
    height: calc(100% - 40px);
    border: 1px solid white;
  }

  @media screen and (min-width: 768px) {
    width: calc(50% - 30px);
    margin-left: 30px;
    margin-top: 30px;

    & > :first-child {
      min-height: calc(50vw * 3 / 4);
      max-height: calc(40vw * 4 / 3);
    }
  }

  @media screen and (min-width: 992px) {
    width: calc(50% - 30px);
    margin-top: 60px;

    & > :first-child {
      min-height: calc(50vw * 3 / 4);
      max-height: calc(40vw * 4 / 3);
    }
  }

  @media screen and (min-width: 1200px) {
    width: calc(33% - 60px);
    margin-left: 60px;

    & > :first-child {
      min-height: calc(33vw * 3 / 4);
      max-height: calc(25vw * 4 / 3);
    }
  }
`

const TextContent = styled.div`
  transform: translateY(-100px);
  margin: 0 15px;
  background-color: white;
  padding: 30px 25px 0;
  margin-bottom: -40px;
  min-height: 150px;
  z-index: 1;

  display: grid;
  grid-template-columns: 1fr;

  & > :last-child {
    margin-top: 20px;
  }

  @media screen and (min-width: 768px) {
    & > p {
      font-size: small;
    }
  }

  @media screen and (min-width: 992px) {
    & > p {
      font-size: inherit;
    }
  }
`

const RealWeddingComponent = ({ image, subtitle, title, summary, slug }) => {
  const windowSize = useWindowSize()

  const elementRef = useRef(0)
  const [hideOnScroll, setHideOnScroll] = useState(true)
  useScrollPosition(
    () =>
      setHideOnScroll(
        elementRef.current.getBoundingClientRect().top > windowSize.height
      ),
    [hideOnScroll]
  )
  return (
    <RealWeddingSection ref={elementRef} hideOnScroll={hideOnScroll}>
      {image.fluid ? (
        <Img
          fluid={image.fluid}
          alt={image.description || "Background Image"}
        />
      ) : image.file.url ? (
        <img
          src={image.file.url}
          alt={image.description || "Background Image"}
        />
      ) : (
        <div />
      )}
      <div name="white stroke" />
      <TextContent>
        <RealWeddingsThirdHeading>{subtitle}</RealWeddingsThirdHeading>
        <MenuSubheading>{title}</MenuSubheading>
        <Copy>{summary}</Copy>
        <StyledLink
          to={`/real-weddings/${slug}/`}
          state={{
            referrer:
              typeof window !== "undefined" // && typeof document !== "undefined"
                ? document.location.pathname
                : undefined, // save the current location and pass with route push
          }}
        >
          Read More
        </StyledLink>
      </TextContent>
    </RealWeddingSection>
  )
}

export default RealWeddingComponent

RealWeddingComponent.propTypes = {
  image: PropTypes.shape({
    description: PropTypes.string,
    file: PropTypes.shape({
      url: PropTypes.string,
    }),
    fluid: PropTypes.object,
  }),
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  summary: PropTypes.string,
  slug: PropTypes.string.isRequired,
}
