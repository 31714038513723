import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Img from "gatsby-image"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import useWindowSize from "./use-window-size"
import Form from "./form"

import { MenuSubheading, Subheading, Copy } from "./typography"

const LazyMap = React.lazy(() => import("./map"))
const isSSR = typeof window === "undefined"

const MapSection = styled.section`
  /* height: calc(var(--vh, 1vh) * 100 - 58px); */
  height: auto;
  /* height: ${(props) => `${props.windowHeight}px`}; */
  display: grid;
  /* row-gap: 60px; */
  grid-template-columns: 1fr;
  /* grid-template-rows: calc(var(--vh, 1vh) * 100 - 58px) auto auto; */
  grid-template-rows: ${(props) => `${props.windowHeight}px`} auto auto;

  opacity: ${(props) => (props.hideOnScroll ? "0" : "1")};
  transform: ${(props) =>
    props.hideOnScroll ? "translateY(50px)" : "translateY(0px)"};

  transition-property: opacity, transform;
  transition-duration: 500ms;
  transition-timing-function: ease-in-out;
  transition-delay: 200ms;

  & > * {
    grid-column: 1;
  }

  & > :first-child {
    height: calc(100% - 200px);
    width: 100%;
    object-fit: cover;
    grid-row: 1;
  }

  & > :nth-child(2) {
    grid-row: 1;
  }

  & > :nth-child(2),
  & > :nth-child(3),
  & > :nth-child(4)  {
    padding-left: 30px;
    padding-right: 15px;
  }

  & > :last-child {
    margin-top: 60px;
  }

  @media screen and (min-width: 768px) {
    grid-template-rows: 50vh auto;
    grid-template-columns: repeat(2, 1fr);
    gap: 60px 30px;

    & > :first-child {
      height: auto;
      grid-column: 1 / span 2;
    }

    & > :nth-child(2) {
      grid-row: 2 / span 2;
      align-self: start;
      padding: 0;
    }

    & > :nth-child(3),
    & > :nth-child(4) {
      grid-column: 2;
      padding-left: 0;
      padding-right: 0;
    }

    & > :nth-child(3) {
      grid-row: 2;
      /* padding-top: 45px; */
    }

    & > :nth-child(4) {
      grid-row: 3;
    }

    & > :last-child {
      margin-top: 0;
    }
  }

  @media screen and (min-width: 992px) {
    grid-template-columns: 45ch 1fr;
    grid-template-rows: auto;
    /* margin-top: 60px; */
    margin-bottom: 60px;
    padding-left: 30px;
    padding-top: 60px;
    /* gap: 45px 0; */

    & > :first-child {
      grid-column: 2;
      grid-row: 1 / span 3;
    }

    & > :nth-child(2),
    & > :nth-child(3),
    & > :nth-child(4) {
      /* padding-top: 30px; */

      padding-bottom: 0;
      grid-column: 1;
    }

    & > :nth-child(2) {
      grid-row: 1;
      margin-top: 45px;
    }
    & > :nth-child(3) {
      grid-row: 2;
    }
    & > :nth-child(4) {
      grid-row: 3;
    }

    & > :last-child {
      margin-bottom: 60px;
    }
  }

  @media screen and (min-width: 1200px) {
    width: 1110px;
    margin: 0px auto 120px;
    padding-top: 120px;
  }
`

const ContactUsSection = styled.section`
  align-self: end;
  justify-self: start;
  background-color: white;
  padding: 25px 25px 60px;
  min-height: 150px;
  z-index: 3;

  display: grid;
  row-gap: 10px;

  & > div {
    width: fit-content;
  }
`

const ContactMethod = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: auto auto 1fr;
  column-gap: 8px;
  align-items: center;

  & > * {
    grid-row: 1;
    &,
    & > * {
      margin-bottom: 0;
    }
  }

  & > img {
    width: 16px;
  }

  & > :last-child {
    justify-self: end;
  }
`

const ContactSectionComponent = ({
  coordinates,
  icon,
  link,
  title,
  contactMethods,
  additionalContent,
}) => {
  const windowSize = useWindowSize()
  const elementRef = useRef(0)
  const [hideOnScroll, setHideOnScroll] = useState(true)
  useScrollPosition(
    () =>
      setHideOnScroll(
        elementRef.current.getBoundingClientRect().top > windowSize.height
      ),
    [hideOnScroll]
  )
  const [windowHeight, setWindowHeight] = useState(0)
  if (windowHeight === 0) {
    setWindowHeight(windowSize.height)
  }
  return (
    <>
      <MapSection
        ref={elementRef}
        hideOnScroll={hideOnScroll}
        windowHeight={windowHeight ? windowHeight - 58 : windowSize.height - 58}
        id="contact-methods"
      >
        {!isSSR && (
          <React.Suspense fallback={<div />}>
            <LazyMap
              lon={coordinates.lon}
              lat={coordinates.lat}
              icon={icon}
              markerUrl={link}
            />
          </React.Suspense>
        )}
        <ContactUsSection>
          <MenuSubheading>{title}</MenuSubheading>
          {contactMethods.map(({ label, text, linkAddress, icon }, index) => (
            <ContactMethod key={index}>
              {icon.fluid ? (
                <Img key={index} fluid={icon.fluid} alt={icon.description} />
              ) : icon.file.url ? (
                <img key={index} src={icon.file.url} alt={icon.description} />
              ) : null}
              <Copy>{`${label} /`}</Copy>
              {linkAddress ? (
                <OutboundLink
                  href={linkAddress}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Copy>{text}</Copy>
                </OutboundLink>
              ) : (
                <Copy>{text}</Copy>
              )}
            </ContactMethod>
          ))}
        </ContactUsSection>
        {additionalContent
          ? additionalContent.map((contentItem, index) => (
              <div key={index}>
                {contentItem.menuSectionHeading ? (
                  <MenuSubheading>
                    {contentItem.menuSectionHeading}
                  </MenuSubheading>
                ) : null}
                {contentItem.menuSubsectionHeading ? (
                  <Subheading>{contentItem.menuSubsectionHeading}</Subheading>
                ) : null}
                {contentItem.menuDescription
                  ? contentItem.menuDescription.menuDescription
                      .split("\n")
                      .map((paragraph, paragraphIndex) => (
                        <Copy key={paragraphIndex}>{paragraph}</Copy>
                      ))
                  : null}
              </div>
            ))
          : null}
      </MapSection>
      <Form />
    </>
  )
}

export default ContactSectionComponent

ContactSectionComponent.propTypes = {
  coordinates: PropTypes.shape({
    lat: PropTypes.number,
    lon: PropTypes.number,
  }).isRequired,
  icon: PropTypes.shape({
    description: PropTypes.string,
    file: PropTypes.shape({
      url: PropTypes.string,
    }),
    fluid: PropTypes.object,
  }),
  link: PropTypes.string,
  title: PropTypes.string,
  contactMethods: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      text: PropTypes.string,
      linkAddress: PropTypes.string,
      icon: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
          url: PropTypes.string,
        }),
        fluid: PropTypes.object,
      }),
    })
  ),
  additionalContent: PropTypes.arrayOf(
    PropTypes.shape({
      menuSectionHeading: PropTypes.string,
      menuSubsectionHeading: PropTypes.string,
      menuDescription: PropTypes.shape({
        menuDescription: PropTypes.string,
      }),
    })
  ),
}
