import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Img from "gatsby-image"
import useMeasure from "react-use-measure"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import useWindowSize from "./use-window-size"
import VenueSummaryGridComponent from "./venue-summary-grid"

import {
  Heading,
  Copy,
  StyledLink,
  Price,
  RealWeddingsThirdHeading,
} from "./typography"

const ImageAndCopySection = styled.section`
  height: calc(var(--vh, 1vh) * 100 - 58px);
  height: ${(props) => `${props.windowHeight}px`};
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  opacity: ${(props) => (props.hideOnScroll ? "0" : "1")};
  transform: ${(props) =>
    props.hideOnScroll ? "translateY(50px)" : "translateY(0px)"};

  transition-property: opacity, transform;
  transition-duration: 500ms;
  transition-timing-function: ease-in-out;
  transition-delay: 200ms;

  & > * {
    grid-column: 1;
    grid-row: 1;
  }

  & > :first-child {
    height: calc(100% - 200px);
    width: 100%;
    object-fit: cover;
  }

  @media screen and (min-width: 768px) {
    height: auto;
    max-height: calc(var(--vh, 1vh) * 100);
    margin-left: 30px;

    & > :first-child {
      width: 80%;
      margin: 0;
      align-self: end;
      justify-self: end;
      justify-self: ${(props) => `${props.right ? "start" : "end"}`};
    }
  }

  @media screen and (min-width: 992px) {
    /* margin: 120px 0; */
    margin: 0 30px;
    margin: 120px 30px;
    grid-template-columns: ${(props) =>
      `${props.right ? "45ch 1fr" : "1fr 45ch"}`};
    column-gap: 60px;
    /* grid-template-columns: 1fr 1fr; */
    height: auto;

    & > :first-child {
      z-index: 2;
      align-self: center;
      justify-self: center;
      grid-column: ${(props) => `${props.right ? "2" : "1"}`};
      width: stretch;
      height: auto;
      max-height: 80vh;
    }
  }
`

const TextContent = styled.div`
  align-self: end;
  justify-self: end;
  margin-left: ${(props) => `${props.right ? "15px" : "0"}`};
  margin-right: ${(props) => `${props.right ? "0" : "15px"}`};
  padding: 25px 25px 45px;
  min-height: 150px;
  z-index: 1;
  background-color: white;

  display: grid;

  & > a {
    display: inline-block;
    margin-top: 15px;
  }

  @media screen and (min-width: 768px) {
    width: calc(565px - 62px - 62px);
    padding: 62px 62px 100px;
    justify-self: ${(props) => `${props.right ? "end" : "start"}`};
    border-left: ${(props) =>
      `${props.realWedding ? "1px solid hsl(0, 0%, 20%)" : "none"}`};
    border-bottom: ${(props) =>
      `${props.realWedding ? "1px solid hsl(0, 0%, 20%)" : "none"}`};
    margin: 0;
  }

  @media screen and (min-width: 992px) {
    grid-column: ${(props) => `${props.right ? "1" : "2"}`};
    place-self: center;
    padding: 0;
    margin-right: 30px;
    width: auto;
    border: none;
    /* &::after {
      display: ${(props) => (props.realWedding ? "unset" : "none")};
      touch-action: none;
      pointer-events: none;
      content: "";
      position: absolute;
      border: 1px solid hsl(0, 0%, 24%);
      width: ${(props) => `${props.refWidth + 62}px`};
      height: ${(props) => `${props.refHeight + 120}px`};
      transform: translateY(-60px) translateX(-62px);
    } */
  }
`

const TitleAndGemRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & > :nth-child(1) {
    margin: 0;
    transform: translateY(5px);
  }

  & > :nth-child(2) {
    width: stretch;
    height: stretch;
    max-width: 60px;
    max-height: 80px;
  }

  @media screen and (min-width: 768px) {
    margin-bottom: 15px;
  }
`

const RealWeddingBlackRule = styled.div`
  display: none;
  @media screen and (min-width: 768px) {
    display: block;
    border: 1px solid hsl(0, 0%, 20%);
    margin: 30px 30px 0 0;
  }

  @media screen and (min-width: 992px) {
    height: 100px;
    border: white;
    z-index: 10;
    margin-left: 30px;
    display: none;
  }
`

const ImageAndCopySectionComponent = ({
  contentOnRight = true,
  image,
  aboveTitleText,
  title,
  text,
  price,
  link,
  linkText,
  venueGem,
  capacity,
  squareFootage,
  dimensions,
  extras,
  internalPageLinks,
  realWedding = false,
}) => {
  const [ref, { width, height }] = useMeasure()
  const windowSize = useWindowSize()

  const elementRef = useRef(0)
  const [hideOnScroll, setHideOnScroll] = useState(true)
  useScrollPosition(
    () =>
      setHideOnScroll(
        elementRef.current.getBoundingClientRect().top > windowSize.height
      ),
    [hideOnScroll]
  )

  const [windowHeight, setWindowHeight] = useState(0)

  if (windowHeight === 0) {
    setWindowHeight(windowSize.height)
  }

  return (
    <ImageAndCopySection
      ref={elementRef}
      right={contentOnRight}
      hideOnScroll={hideOnScroll}
      windowHeight={windowHeight ? windowHeight - 58 : windowSize.height - 58}
      refHeight={height}
      refWidth={width}
    >
      {image.fluid ? (
        <Img
          fluid={image.fluid}
          alt={image.description || "Background Image"}
          objectFit="contain"
          objectPosition={`${!contentOnRight ? "right" : "left"} center`}
          // imgStyle={{
          //   objectFit: "contain",
          //   objectPosition: `${!contentOnRight ? "right" : "left"} center`,
          // }}
          style={{
            marginBottom: windowSize.width >= 992 ? null : `${height - 62}px`,
            height:
              windowSize.width >= 992
                ? null
                : windowSize.width >= 768
                ? `${Math.min(
                    windowSize.height - height + 62,
                    windowSize.width *
                      0.7 *
                      (image.file.details.image.height /
                        image.file.details.image.width)
                  )}px`
                : "auto", // Need the smaller of the total height of the window or the top of a horizontal image
          }}
        />
      ) : image.file.url ? (
        <img
          src={image.file.url}
          alt={image.description || "Background Image"}
          style={{
            marginBottom: `${height - 62}px`,
            height:
              windowSize.width >= 768
                ? `${Math.min(
                    windowSize.height - height + 62,
                    windowSize.width *
                      0.7 *
                      (image.file.details.image.height /
                        image.file.details.image.width)
                  )}px`
                : "auto", // Need the smaller of the total height of the window or the top of a horizontal image
          }}
        />
      ) : (
        <div />
      )}
      {realWedding ? <RealWeddingBlackRule /> : null}
      <TextContent
        right={contentOnRight}
        ref={ref}
        realWedding={realWedding}
        refHeight={height}
        refWidth={width}
      >
        {aboveTitleText ? (
          <RealWeddingsThirdHeading>{aboveTitleText}</RealWeddingsThirdHeading>
        ) : null}
        {title && venueGem ? (
          <TitleAndGemRow>
            <Heading>{title}</Heading>
            {venueGem.fluid ? (
              <Img
                fluid={venueGem.fluid}
                alt={venueGem.description || "Background Image"}
              />
            ) : venueGem.file.url ? (
              <img
                src={venueGem.file.url}
                alt={venueGem.description || "Background Image"}
              />
            ) : null}
          </TitleAndGemRow>
        ) : title ? (
          <Heading>{title}</Heading>
        ) : null}
        <VenueSummaryGridComponent
          capacity={capacity}
          squareFootage={squareFootage}
          dimensions={dimensions}
          extras={extras}
        />
        {text
          ? text
              .split("\n")
              .map((line, index) => <Copy key={index}>{line}</Copy>)
          : null}
        {price ? <Price>{price}</Price> : null}
        {RegExp("^/.*/$|^/$").test(link) ? ( // link.match(^\/.*\/$|^\/$)
          <StyledLink
            to={link}
            state={{
              referrer:
                typeof window !== "undefined" // && typeof document !== "undefined"
                  ? document.location.pathname
                  : undefined, // save the current location and pass with route push
            }}
          >
            {linkText ? linkText : link}
          </StyledLink>
        ) : link ? (
          <StyledLink href={link}>{linkText ? linkText : link}</StyledLink>
        ) : null}

      {internalPageLinks
          ? internalPageLinks.map((internalPageLink, index) => (
            internalPageLink.menuName?.match(/dummy/i) || internalPageLink.title?.match(/dummy/i) ?  (
              null
              )
            : internalPageLink.__typename === "ContentfulMenu" ? (
              <StyledLink
                key={index}
                to={`/menu/${internalPageLink.slug}/`}
                state={{
                  referrer:
                    typeof window !== "undefined" // && typeof document !== "undefined"
                      ? document.location.pathname
                      : undefined, // save the current location and pass with route push
                }}
              >
                View our {internalPageLink.menuName}
              </StyledLink>)
            : internalPageLink.__typename === "ContentfulPromotion" ? (
              <StyledLink
                key={index}
                to={`/promotions/${internalPageLink.slug}/`}
                state={{
                  referrer:
                    typeof window !== "undefined" // && typeof document !== "undefined"
                      ? document.location.pathname
                      : undefined, // save the current location and pass with route push
                }}
              >
                View our {internalPageLink.title}
              </StyledLink>)
             : internalPageLink.__typename === "ContentfulPdfMenu" ? (
              <StyledLink
                key={index}
                href={`https:${internalPageLink.pdf.file.url}`}
              >
                View our {internalPageLink.menuName}
              </StyledLink>
            ) :
            null))
          : null}
      </TextContent>
    </ImageAndCopySection>
  )
}

export default ImageAndCopySectionComponent

ImageAndCopySectionComponent.propTypes = {
  image: PropTypes.shape({
    fluid: PropTypes.object,
    description: PropTypes.string.isRequired,
    file: PropTypes.shape({
      url: PropTypes.string,
      details: PropTypes.shape({
        image: PropTypes.shape({
          height: PropTypes.number,
          width: PropTypes.number,
        }),
      }),
    }),
  }),
  contentOnRight: PropTypes.bool,
  aboveTitleText: PropTypes.string,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  price: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  venueGem: PropTypes.shape({
    description: PropTypes.string.isRequired,
    file: PropTypes.shape({
      url: PropTypes.string,
    }),
    fluid: PropTypes.object,
  }),
  capacity: PropTypes.string,
  squareFootage: PropTypes.string,
  dimensions: PropTypes.string,
  extras: PropTypes.string,
  internalPageLinks: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      menuName: PropTypes.string,
    }),
    PropTypes.shape({
      slug: PropTypes.string,
      title: PropTypes.string,
    }),
    PropTypes.shape({
      menuName: PropTypes.string,
      pdf: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    })
  ),
  realWedding: PropTypes.bool,
}
